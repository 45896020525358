import React from "react";
import { graphql } from "gatsby";
import Layout2 from "../components/layout2";
import SEO from "../components/seo";

const Testimonial = ({ title, name, jobTitle, children }) => {
  return (
    <div className="w-full sm:w-1/2 -mx-2 flex flex-col items-start">
      <div className="mx-2">
        <h2 className="text-3xl text-brand">{title}</h2>
      </div>
      <div className="w-full mt-2 mx-2 mb-5">
        <blockquote className="testimonial-quote">{children}</blockquote>
      </div>
    </div>
  );
};

const Testimonials = ({ data }) => {
  const testimonials = data.allMarkdownRemark.edges;
  return (
    <Layout2>
      <SEO title="Referenzen" />
      <div className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 flex flex-col items-start">
        <div className="w-3/4 md:w-1/2 mt-8 ">
          <h1 className="text-4xl md:text-5xl text-brand3">Referenzen</h1>
        </div>
        <div className="text-xl tracking-wide font-normal mt-4">
          <p>
            Was sagen unsere Kunden? Weitere Meinungen unserer überglücklicher
            Kunden finden Sie auf Google und Facebook.
          </p>
        </div>
        <div className="w-full mt-16 flex flex-row flex-wrap justify-between">
          {testimonials.map(({ node }) => {
            const { title, path } = node.frontmatter;
            const html = node.html;
            return (
              <Testimonial key={path} title={title}>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </Testimonial>
            );
          })}
        </div>
      </div>
    </Layout2>
  );
};

export const query = graphql`
  query Testimonials {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/testimonials/" } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
          }
          html
        }
      }
    }
  }
`;

export default Testimonials;
